body {
  height: 100%;
  margin: 0;
  background-color: rgb(5, 5, 21);
  display: flex;
  flex-direction: column; 
}

.App {
  font-family: Arial, sans-serif;
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: rgb(8, 8, 15);
  min-height: 100vh;
  display: flex;
  flex-direction: column; 
}

.top-bar {
  background: linear-gradient(135deg, rgb(0, 0, 0), rgba(12, 15, 36, 0.7), rgba(15, 19, 41, 0.7));
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  height: auto;
}

.logo {
  height: 80px;
  width: 80px;
}

.container-logo-app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.logoApps {
  max-width: 100%;
  height: auto;
  filter: hue-rotate(80deg);
}

.page-name {
  font-size: 1.5rem;
  color: #e2e2e2;
}

/*Content*/
.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  background: radial-gradient(circle, rgba(5, 35, 80, 0.9), rgba(5, 17, 44, 0.7));
  padding: 20px;
  flex-grow: 1;
}

.cards-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0px;
}

.card {
  background: linear-gradient(145deg, rgb(38, 38, 58), rgb(20, 20, 35));
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 20px;
  max-width: 100%;
  width: 90%;
  max-width: 350px;
  text-align: left;
  margin-bottom: 5px;
  box-sizing: border-box;
}

.card h2 {
  font-size: 1.25rem;
  margin-bottom: 10px;
  color: rgba(255, 255, 255, 0.9)

}

.card p {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.9)
}

/* Footer */
.footer {
  background: linear-gradient(135deg, rgb(0, 0, 0), rgba(12, 15, 36, 0.7), rgba(15, 19, 41, 0.7));
  color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  text-align: center;
  margin-top: auto;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 15px;
}

.social-links a {
  color: rgba(7, 98, 134, 0.9);
  text-decoration: none;
  font-size: 1rem;
}

.social-links a:hover {
  text-decoration: underline;
}

.policies-text {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.9);
}

a {
  color: rgba(7, 98, 134, 0.9);
  text-decoration: none;
}

a:hover {
  color: rgba(6, 136, 187, 0.9);
}

/*Medium screen*/
@media (max-width: 768px) {
  .top-bar {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .logo {
    height: 60px;
    width: 60px;
  }

  .page-name {
    font-size: 1.2rem;
  }

  .cards-container {
    flex-direction: column;
    gap: 10px;
  }

  .card {
    width: 100%; /* Más pequeño para pantallas reducidas */
    margin-bottom: 15px; /* Ajusta el margen inferior */
  }

  .footer {
    padding: 15px;
  }

  .social-links {
    flex-direction: column;
    gap: 10px;
  }
}

/*Small screen*/
@media (max-width: 480px) {
  .top-bar {
    padding: 10px;
  }

  .logo {
    height: 50px;
    width: 50px;
  }

  .cards-container {
    gap: 15px;
  }

  .card {
    padding: 15px; /* Reduce el padding en pantallas muy pequeñas */
  }

  .social-links {
    flex-direction: column;
    gap: 10px;
  }
}
